import React, {useEffect, useState} from 'react'
import {TObservablePage} from '../../types/commerce'
import {flowResult} from 'mobx'
import {observer} from 'mobx-react-lite'
import {getServerState} from 'react-instantsearch-hooks-server'
import {useCategoryStore, useContentStore, useGlobalStore, useOrderStore} from '../../store/hooks/useStore'
import {Layout} from '../../components/layout'
import {Box, Container, Stack} from '../../vanilla'
import PageNotFound from '../../pages/page-not-found'
import CategoryPage from '../category'
import {SlotContentType} from '../category'
import {DisplaySlots} from '../category'
import {renderToString} from 'react-dom/server'
//@ts-ignore
import {InstantSearch as AlgoliaInstantSearch, Pagination} from 'react-instantsearch-hooks-web'
import {Seo} from '../../components/seo'
import InstantSearch, {Results, ResultsHeader} from '../../components/algolia/instantsearch'
import {Configure} from 'react-instantsearch-hooks-web'
import {CommerceAPIProvider, StoreProvider} from '../../contexts'
// @ts-ignore route conflict
import {StaticRouter} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import algoliasearch from 'algoliasearch/lite'
import algoliaRouting from '../../components/algolia/routing'
import {Slot} from '../../components/cms/Slot'
import {
  PLPSkeletonBreadcrumbs,
  PLPSkeletonGrid,
  PLPSkeletonLayout,
  PLPSkeletonStatsFilters,
  PLPSkeletonTiles,
} from '../../components/skeletons'
import {EditOrderStickyBanner} from '../../components/edit-order-sticky-banner'
import {RefinementsDisplay} from '../../components/algolia/instantsearch/refinements-display'
import LoadingOverlay from '../../components/loading-overlay'

import { HITS_PER_PAGE } from '../../constants'
import { useLocation } from 'react-router-dom'
import {DateUtils} from '../../utils/date-utils'
import {isServer} from '../../utils/utils'


interface ContentProps {
  deliveryId?: string
  pageType?: 'CATEGORY' | 'CONTENT'
  categoryId?: string
  serverState: any
  serverUrl: string
}

interface Refinement {
  [key: string]: string[]
}

const CATEGORY_BANNER_SCHEMA = 'https://www.iceland.co.uk/slot/category-banner-slot.json'
const CATEGORY_HEADLINE_BANNER_SCHEMA =
  'https://www.iceland.co.uk/content/category-headline-banner.json'
const IN_GRID_PROMO_SCHEMA = 'https://www.iceland.co.uk/slot/in-grid-positioning-slot.json'
const SEO_PAGE_CONTENT_SCHEMA = 'https://www.iceland.co.uk/slot/seo-page-content.json'

const Content: TObservablePage<ContentProps> = observer(
  ({deliveryId, pageType, categoryId, serverState, serverUrl}) => {
    const contentStore = useContentStore()
    const {fetchAllCategories, status} = useCategoryStore()
    const {editMode} = useOrderStore()
    const {
      selectedAvailabilityDayOffset,
      selectedStoreId,
      dataLoadedForAccuratePricingAndAvailabilty,
    } = useGlobalStore()

    const [showPageNotFound, setShowPageNotFound] = useState(false)
    const location = useLocation()

    const path = location.pathname
    const route = contentStore.routeMap[path]
    const routeInfo = route?.split('|')
    deliveryId = deliveryId || (routeInfo?.length > 0 ? routeInfo[0] : undefined)

    const availabilityFilter = `matrixAvailability.${selectedAvailabilityDayOffset}:-${selectedStoreId}`
    const inStockFilter = `in_stock:true`
    const defaultLoadingState = pageType === 'CATEGORY' ? false : true
    const [loading, setLoading] = useState(defaultLoadingState)

    const getCategories = async () => await fetchAllCategories()
    useEffect(() => {
      if (status === 'idle') {
        getCategories()
      }  
    })

    useEffect(() => {
      if (!deliveryId) {
        setShowPageNotFound(true)
      } else {
        setShowPageNotFound(false)
      }
    }, [dataLoadedForAccuratePricingAndAvailabilty, deliveryId])

    if (showPageNotFound) {
      return (
        <Layout>
          <Seo title="404" />
          <PageNotFound />
        </Layout>
      )
    }

    useEffect(() => {
      if (deliveryId !== undefined && pageType !== undefined && categoryId !== undefined) {
        setLoading(false)
      }
    }, [deliveryId, pageType, categoryId])

    if (loading) {
      return (
        <LoadingOverlay isLoading />
      )
    }

    if (!deliveryId) {
      // return a 404 page component
      return (
        <Layout>
          <Seo title="404" />
          <PageNotFound />
        </Layout>
      )
    }

    const page = contentStore.contentById[deliveryId]
    let hasCategoryHeadlineBanner = false
    const bannerSlot =
      page?.slots &&
      page?.slots?.length > 0 &&
      page?.slots?.map((slotGroup: SlotContentType, idx: number) => {
        if (slotGroup?._meta?.schema === CATEGORY_BANNER_SCHEMA) {
          if (
            slotGroup.default?.find(({_meta}) => _meta.schema === CATEGORY_HEADLINE_BANNER_SCHEMA)
          ) {
            hasCategoryHeadlineBanner = true
          }
          return <DisplaySlots slots={slotGroup} key={`${slotGroup?._meta?.deliveryId}-${idx}`} />
        }
      })

    // Check for In-Grid Promo Content and Filter out any expired Content
    const inGridPromos: SlotContentType =
      page?.slots &&
      page?.slots?.length > 0 &&
      page?.slots?.find(
        (slotGroup: SlotContentType) => {
          if (slotGroup?._meta?.schema !== IN_GRID_PROMO_SCHEMA) {
            return false
          }
          
          // Check if we have a lifecycle expiry time
          if (!slotGroup?._meta?.lifecycle?.expiryTime) {
            // No Expiry time set, this must be default content, let it render
            return true
          }

          // Check we're between the start/end dates for the edition?
          const expiryTime = new Date(slotGroup._meta.lifecycle.expiryTime)
        
          // Should Return True if we haven't hit the expiry time yet
          return DateUtils.isBefore(new Date(), expiryTime)
        }
      )

    // Find the seo content slot (category pages)
    const seoSlot = page?.slots?.find(
      (slot: { _meta?: { schema?: string }; seo?: { pageTitle?: string; pageDescription?: string } }) =>
        slot?._meta?.schema === SEO_PAGE_CONTENT_SCHEMA
    )

    // Create the seo object
    const seo = {
      pageTitle: seoSlot?.seo?.pageTitle || page?.seo?.pageTitle || `${page?.name} | Iceland Foods`,
      pageDescription: seoSlot?.seo?.pageDescription || page?.seo?.pageDescription,
    }    
            
    //Filter queries from URL to be applied on first load
    const searchParams = new URLSearchParams(location.search)
    const prefn1 = searchParams.get('prefn1')
    const prefv1 = searchParams.get('prefv1')
    const refinement = prefn1 && prefv1 ? { [prefn1]: [prefv1] } : {}

    return (
      <Layout>
        {pageType === 'CATEGORY' && editMode && <EditOrderStickyBanner />}
        {page && <Seo title={seo.pageTitle} description={seo.pageDescription} />}
        <Container
          padding="0px"
          style={page && pageType === 'CATEGORY' ? {minHeight: '800px'} : {}}
        >
          {page && pageType === 'CATEGORY' && (
            <CategoryPage
              slots={page?.slots}
              seo={seo}
              categoryId={categoryId}
              name={page?.name}
              categoryTree={page?.parentCategoryTree}
            >
              {(isServer || dataLoadedForAccuratePricingAndAvailabilty) ? <InstantSearch
                categoryTree={page?.parentCategoryTree}
                seo={seo}
                serverState={serverState}
                serverUrl={serverUrl}
                category={{name: page?.name, id: categoryId || ''}}
                contentAboveHeader={bannerSlot}
                hasCategoryHeadlineBanner={hasCategoryHeadlineBanner}
                inGridPromos={inGridPromos}
                presentationAttributes={page.presentationAttributes}
                h2={{
                  textAlignment: page?.pageHeading?.textAlignment?.toLowerCase(),
                  heading2: page?.pageHeading?.heading2,
                }}
                pageName={page?.pageHeading?.heading}
              >
                <Configure
                  clickAnalytics
                  maxValuesPerFacet={100}
                  facetFilters={['priceRange', 'brand', 'categoryPageId', availabilityFilter, inStockFilter]}
                  facets={['priceRange', 'brand', 'categoryPageId','filterPackSize','filterBrand','filterType','filterWeight','filterLitre','manufacturerSKU','filterServing', 'buyingSubGroup1','buyingSubGroup2','chamber','buyingGroup','primary_category_id']}
                  filters={`categoryPageId:${categoryId}`}
                  facetsRefinements={refinement}
                  hitsPerPage={HITS_PER_PAGE}
                  highlightPostTag='__/ais-highlight__'
                  highlightPreTag='__ais-highlight__'
                />
              </InstantSearch>
              :
              <PLPSkeletonLayout>
                <PLPSkeletonBreadcrumbs />
                <PLPSkeletonStatsFilters />
                <PLPSkeletonGrid>
                  <PLPSkeletonTiles />
                </PLPSkeletonGrid>
              </PLPSkeletonLayout>}
            </CategoryPage>
          )}
        </Container>
        {page && pageType !== 'CATEGORY' && <Slot data={page} />}
      </Layout>
    )
  },
)

Content.getProps = async ({store, location, res, req}) => {

  await flowResult(store.contentStore.buildRoutingMapAndNavMenu())

  const path = location.pathname
  const route = store.contentStore?.routeMap[path]

  if (route) {
    const [deliveryId, pageType, categoryId] = route.split('|')
    if (isServer) {
      
      const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY, {
        // Add the referer header to all Algolia requests so we can limit API key access
        ...(process.env.APP_ORIGIN !== 'http://localhost:3000') && {
          headers: {
            'Referer': process.env.APP_HOSTNAME
          }
        }
      })
      
      await flowResult(store.contentStore.fetchItemById(deliveryId))
      const page = store.contentStore.contentById[deliveryId]
      let hasCategoryHeadlineBanner = false
      const bannerSlot =
        page?.slots &&
        page?.slots?.length > 0 &&
        page?.slots?.map((slotGroup: SlotContentType, idx: number) => {
          if (slotGroup?._meta?.schema === CATEGORY_BANNER_SCHEMA) {
            if (
              slotGroup.default?.find(({_meta}) => _meta.schema === CATEGORY_HEADLINE_BANNER_SCHEMA)
            ) {
              hasCategoryHeadlineBanner = true
            }
            return <DisplaySlots slots={slotGroup} key={`${slotGroup?._meta?.deliveryId}-${idx}`} />
          }
        })

      const protocol = req.headers.referer?.split('://')[0] || 'https'
      const serverUrl = `${protocol}://${req.headers.host}${req.originalUrl}`
      const {
        algoliaIndexBase, 
        selectedAvailabilityDayOffset,
        selectedStoreId,} = store.globalStore
      const searchIndex = `${algoliaIndexBase}__products__default`
      const routing = algoliaRouting(serverUrl, searchIndex, algoliaIndexBase)
      const availabilityFilter = `matrixAvailability.${selectedAvailabilityDayOffset || 1}:-${selectedStoreId || 0}`
      const inStockFilter = `in_stock:true`

      // Create the seo object
      const seo = {
        pageTitle: page?.seo?.pageTitle || page?.SEO?.pageTitle || `${page?.name} | Iceland Foods`,
        pageDescription: page?.seo?.pageDescription || page?.SEO?.pageDescription,
      }
      const serverState =
      pageType === 'CATEGORY'
        ? await getServerState(
            <StaticRouter location={location}>
              <CommerceAPIProvider value={store.api}>
                <StoreProvider value={store}>
                  <IntlProvider locale="en-GB">
                    <Layout>
                      {page && (
                        <Seo title={page.SEO?.seoTitle} description={page.SEO?.metaDescription} />
                      )}
                      <Container padding="0px">
                        {page && pageType === 'CATEGORY' && (
                          <CategoryPage
                            slots={page?.slots}
                            seo={page?.seo}
                            categoryId={categoryId}
                            name={page?.name}
                            categoryTree={page?.parentCategoryTree}
                          >
                            <AlgoliaInstantSearch
                              searchClient={searchClient}
                              indexName={searchIndex}
                              initialUiState={{
                                [searchIndex]: {query: ''},
                              }}
                              routing={routing}
                            >
                              <Configure
                                clickAnalytics
                                maxValuesPerFacet={100}
                                facetFilters={['priceRange', 'brand', 'categoryPageId', availabilityFilter, inStockFilter]}
                                facets={['priceRange', 'brand', 'categoryPageId','filterPackSize','filterBrand','filterType','filterWeight','filterLitre','manufacturerSKU','filterServing', 'buyingSubGroup1','buyingSubGroup2','chamber','buyingGroup','primary_category_id']}
                                filters={`categoryPageId:${categoryId}`}
                                hitsPerPage={HITS_PER_PAGE}
                                highlightPostTag='__/ais-highlight__'
                                highlightPreTag='__ais-highlight__'
                                tagFilters=''
                                
                              />
                              <Stack spacing={['24px']} paddingBottom={'20px'}>
                                <ResultsHeader
                                  category={{name: page?.name, id: categoryId || ''
                                }}
                                  content={bannerSlot}
                                  presentationAttributes={page.presentationAttributes}
                                  hasCategoryHeadlineBanner={hasCategoryHeadlineBanner}
                                  h2={{
                                    textAlignment: page?.pageHeading?.textAlignment?.toLowerCase(),
                                    heading2: page?.pageHeading?.heading2,
                                  }}
                                  pageName={page?.pageHeading?.heading}
                                />
                                <Box aria-hidden borderBottom="1px" borderColor="border0" />
                                <Box display={['none', 'block']}>
                                  <RefinementsDisplay
                                    category={{name: page.name, id: categoryId || ''}}
                                  />
                                </Box>
                                <Results categoryTree={page?.parentCategoryTree} seo={seo} />
                                <Pagination />
                              </Stack>
                            </AlgoliaInstantSearch>
                          </CategoryPage>
                        )}
                      </Container>
                      {page && pageType !== 'CATEGORY' && <Slot data={page} />}
                    </Layout>
                  </IntlProvider>
                </StoreProvider>
              </CommerceAPIProvider>
            </StaticRouter>,
            {renderToString},
          )
        : {}


      return {serverState, serverUrl, deliveryId, pageType, categoryId}
    } else {
      store.contentStore.fetchItemById(deliveryId)
    }

    return {deliveryId, pageType, categoryId}
  } else {
    if (res) {
      res.status(404)
    }
  }
}

export default Content